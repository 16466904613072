export const completedVertex =
[ 'AAAAA',
  'AAAAFH',
  'AAAFHA',
  'AAFHAA',
  'AFHAAA',
  'FHAAAA',
  'HAAAAF',
  'AAFHAFH',
  'AFHAFHA',
  'FHAFHAA',
  'HAFHAAF',
  'AFHAAFH',
  'FHAAFHA',
  'HAAFHAF',
  'AFDBH',
  'FDBHA',
  'DBHAF',
  'BHAFD',
  'HAFDB',
  'CCCCC',
  'CCCG',
  'CCGC',
  'CGCC',
  'GCCC',
  'CGG',
  'GCG',
  'GGC',
  'BDE',
  'DEB',
  'EBD'
];