export const allowedVerts = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "AA",
  "AF",
  "BD",
  "BH",
  "CC",
  "CG",
  "DB",
  "DE",
  "EB",
  "FD",
  "FH",
  "GC",
  "GG",
  "HA",
  "AAA",
  "AAF",
  "AFD",
  "AFH",
  "BHA",
  "CCC",
  "CCG",
  "CGC",
  "DBH",
  "FDB",
  "FHA",
  "GCC",
  "HAA",
  "HAF",
  "AAAA",
  "AAAF",
  "AAFH",
  "AFDB",
  "AFHA",
  "BHAF",
  "CCCC",
  "DBHA",
  "FDBH",
  "FHAA",
  "FHAF",
  "HAAA",
  "HAAF",
  "HAFD",
  "HAFH",
  "AAAAF",
  "AAAFH",
  "AAFHA",
  "AFHAA",
  "AFHAF",
  "FHAAA",
  "FHAAF",
  "FHAFH",
  "HAAAA",
  "HAAFH",
  "HAFHA",
  "AAFHAF",
  "AFHAAF",
  "AFHAFH",
  "FHAAFH",
  "FHAFHA",
  "HAAFHA",
  "HAFHAA",
];
